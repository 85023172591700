.table {
  & :global(td) {
    border-top: 0 !important;
    border-bottom: 1px solid rgba(5, 5, 5, 0.06) !important;
  }

  & :global(td):nth-child(1) {
    width: 26.6%;
  }
  & :global(td):nth-child(2) {
    width: 26.6%;
  }
  & :global(td):nth-child(3) {
    width: 26.6%;
  }
  & :global(td):nth-child(4) {
    width: 4.5%;
  }
  & :global(td):nth-child(5) {
    width: 4.5%;
  }
  & :global(td):nth-child(6) {
    width: 4.5%;
  }
}

.clientsForm {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 2%;
  padding-bottom: 23.5%;

  & :global(.ant-form) {
    width: 100% !important;
  }
  & :global(.ant-form-item) {
    width: 30% !important;
  }
  & :global(.ant-form-item):nth-child(5) {
    width: 65% !important;

    & :global(#clientNote) {
      min-height: 119px !important;
    }
  }
  & :global(.ant-form-item):nth-child(6) {
    width: 45% !important;
  }
  & :global(.ant-form-item):nth-child(7) {
    width: 45% !important;
  }
}

.clientSearchBar {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-end;
  box-sizing: border-box;
  width: 100%;
  margin-top: 50px;

  & :global(.ant-form-item) {
    width: 50%;
    max-width: 350px;
  }
  & :global(.ant-form-item):nth-child(2) {
    width: 20%;
    display: flex;
    justify-content: flex-start;
    margin-left: 2%;
  }

  & :global(.ant-form-item-label > label) {
    font-size: 17px;
    font-weight: 600;
  }
}

.newEntry {
  margin-top: 20px;
  background-color: #33a921;
}

.newEntry:hover {
  background-color: #2cbd16 !important;
}

.modalStyle {
  height: 85%;
  & :global(.ant-btn-primary) {
    background-color: #33a921;
  }
  & :global(.ant-btn-primary):hover {
    background-color: #2cbd16 !important;
  }
}

.deleteEntry {
  margin-top: 20px;
  background-color: #990000;
}

.deleteEntry:hover {
  background-color: #af0000 !important;
}

.textContainer {
  justify-content: center;
}

.text {
  margin: 20px 0 30px 0;
  font-size: 16px;
}
