.table {
    & :global(td) {
      border-top: 0 !important;
      border-bottom: 1px solid rgba(5, 5, 5, 0.06) !important; 
    }
  
    & :global(td):nth-child(1) {
      width:20%;
      max-width: 50px !important;
    }
    & :global(td):nth-child(2) {
      width:60%;
      max-width: 50px !important;
    }
    & :global(td):nth-child(3) {
      width:6%,
    }
    & :global(td):nth-child(4) {
      width:6%,
    }
  }
  
  .suppliersForm {
    display:flex !important; 
    flex-wrap: wrap;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 2%;
    padding-bottom: 10%;
  
    & :global(.ant-form) {
      width:100% !important;
    }
    & :global(.ant-form-item) {
      width:45% !important;
      margin-left: 2.5%;
    }
  }
  
  .suppliersSearchBar {
    display:flex !important; 
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-end;
    box-sizing: border-box;
    width: 80%;
    margin-top: 50px;
    
    & :global(.ant-form-item) {
      width:30%;
      max-width: 350px;
    }
    & :global(.ant-form-item):nth-child(2) {
      width:20%;
      max-width: 100px;
      display: flex;
      justify-content: flex-start;
      margin-right: 2.153vw !important;
      margin-left: 2% 

    }
  
    & :global(.ant-form-item-label> label) {
    font-size: 17px;
    font-weight: 600;
    } 
  
  }
  
  .newEntry {
    margin-top: 20px;
    background-color: #33a921;
  }
  
  .newEntry:hover {
    background-color: #2cbd16 !important;
  }

  .deleteEntry {
    margin-top: 20px;
    background-color: #990000;
  }
  
  .deleteEntry:hover {
    background-color: #af0000 !important;
  }

  .modalStyle {
    height: 85%;
    & :global(.ant-btn-primary) {
      background-color: #33a921;
    }
    & :global(.ant-btn-primary):hover {
      background-color: #2cbd16 !important;
    }
  }

  .textContainer {
    justify-content: center;
  }
  
  .text {
    margin: 20px 0 30px 0;
    font-size: 16px;
  }
  