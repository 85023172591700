.navbar {
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding-left: 2rem;
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.menu {
  background-color: #f0bc47;
}

@media (min-width: 992px) {
  .menu {
    display: none;
  }
}
.logo {
  height: 32px;
  margin-left: 1rem;
}

.button {
  margin-right: 40px;
}
