.calendar {
  border-radius: 15px;

  .people {
    margin-left: 10px !important;
  }

  & :global(.ant-picker-calendar-date-value) {
    font-size: 18px;
  }

  & :global(.ant-radio-group-outline) {
    display: none;
  }

  & :global(.ant-picker-calendar-header) {
    margin-right: 1%;
    padding: 25px 0;
  }

  & :global(.ant-picker-cell) {
    cursor: unset !important;
    pointer-events: none !important;
  }

  & :global(.ant-picker-cell.ant-picker-cell-in-view) {
    cursor: unset !important;
    pointer-events: none !important;
  }

  & :global(th) {
    font-size: 18px;
  }

  & :global(.dataContainer) {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
  }

  & :global(.ant-picker-cell-disabled) {
    & :global(.dataContainer) {
      display: flex;
      flex-direction: column;
      color: rgba(0, 0, 0, 0.25);
      pointer-events: none;
      margin-bottom: 25px;
    }
  }

  & :global(tbody) {
    & :global(tr) {
      height: 200px;
    }

    & :global(.ant-picker-calendar-date-content) {
      height: 100% !important;
    }
  }
}
