/* @import "~antd/dist/antd.css"; */
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
.App {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
}
.content {
  padding: 1rem;
  overflow-y: scroll;
  height: calc(100vh - 84px);
}

body {
  overflow: hidden;
}
