.multipleCheckboxContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 55.556vw;
  margin-top: 44px;
  height: 103px;

  & :global(.ant-form-item) {
    margin-right: 26px !important;
    width: 95px !important;
  }

  & :global(.ant-form-item-control-input-content) {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 98px;
    justify-content: space-between;
    font-size: 14px;
    text-transform: uppercase;
  }

  & :global(.ant-checkbox-group) {
    display: flex;
    margin-bottom: 0;
    margin-top: -20px;
  }

  & :global(.ant-checkbox-wrapper) {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
  }
}

.indicator {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff85;
  z-index: 11;
  opacity: 0.9;
}
