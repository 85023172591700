.dropDownFooter {
  width: 93% !important; //keep this value for prod env
  display: flex !important;
  flex-direction: column !important;
}

.dropDownButtons {
  display: flex !important;
  justify-content: space-between !important;
}

.dropDownButtonsEnd {
  display: flex !important;
  justify-content: flex-end !important;
  gap: 10% !important;
}

.input {
  width: 100% !important;
}

.newEntry {
  margin-top: 10px !important;
  background-color: #33a921 !important;
  color: white !important;
}

.newEntry:hover {
  background-color: #2cbd16 !important;
}

.deleteEntry {
  color: white !important;
  margin-top: 10px !important;
  background-color: #990000;
}

.deleteEntry:hover {
  background-color: #af0000 !important;
}

.editEntry {
  margin-top: 10px !important;
}

:global(.ant-space) {
  padding: 6px 8px 4px !important;
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;

  :global(.ant-space-item) {
    width: 100% !important;
  }
}

.dropDownButtonCenter {
  display: flex !important;
  justify-content: center !important;
}
