.newEntry {
  margin-top: 20px;
  background-color: #33a921;
}

.newEntry:hover {
  background-color: #2cbd16 !important;
}

.modalStyle {
  height: 85%;

  & :global(.ant-btn-primary) {
    background-color: #33a921;
  }

  & :global(.ant-btn-primary):hover {
    background-color: #2cbd16 !important;
  }
}

.billsForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  box-sizing: border-box;
  margin-left: 5%;
  padding: 2%;

  & :global(.ant-modal-title) {
    margin-left: 8%;
  }

  & :global(.ant-modal) {
    width: 100% !important;
  }

  & :global(.ant-modal-content) {
    padding: 2%;
  }

  & :global(.ant-form-item) {
    width: 50%;
  }

  & :global(.ant-select) {
    width: 70%;
  }

  & :global(.ant-input) {
    width: 70%;
  }

  & :global(.ant-input-number) {
    width: 70%;
    color: black !important;
  }

  & :global(.ant-picker) {
    width: 70%;
  }
}

.table {
  & :global(td) {
    border-top: 0;
    border-bottom: 1px solid rgba(5, 5, 5, 0.06) !important;
  }

  & :global(td):nth-child(9) {
    width: 5%;
  }

  & :global(td):nth-child(10) {
    width: 5%;
  }

  & :global(td) {
    border-top: 0 !important;
    border-bottom: 1px solid rgba(5, 5, 5, 0.06) !important;
  }
}

.billsSearchBar {
  display: flex !important;
  align-items: flex-end;
  width: 80%;
  margin-top: 50px;

  & :global(.ant-form-item) {
    width: 30%;
  }

  & :global(.ant-form-item-label > label) {
    font-size: 17px;
    font-weight: 600;
  }

  & :global(.ant-form-item):nth-child(1) {
    display: flex;
    width: 30%;
  }

  & :global(.ant-form-item):nth-child(2) {
    display: flex;
    justify-content: flex-start;
    width: 15%;
  }

  & :global(.ant-picker) {
    width: 90%;
  }
}

.deleteEntry {
  margin-top: 20px;
  background-color: #990000;
}

.deleteEntry:hover {
  background-color: #af0000 !important;
}

.text {
  margin: 20px 20px 30px 0;
  font-size: 16px;
}

.textContainer {
  justify-content: center;
}
