.formContainer {
  display: flex;
  justify-content: center;
}

.form {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 168px;

  & :global(.ant-form-item-control) {
    width: 100% !important;
  }

  & input {
    cursor: default !important;
    color: black !important;
  }

  & :global(.ant-form-item):nth-child(3) {
    margin-top: 20px;
  }
}

.imgContainer {
  margin-top: 2%;
  justify-content: center;
}
