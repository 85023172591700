.reservationForm {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 50px;

  & :global(.ant-form-item):nth-child(1) {
    width: 70%;
  }

  & :global(.ant-form-item):nth-child(2) {
    width: 24%;
  }

  & :global(.ant-form-item):nth-child(3) {
    width: 60%;
  }

  & :global(.ant-form-item):nth-child(4) {
    width: 60%;
    & :global(.ant-select) {
      width: 100%;
    }
  }

  & :global(.ant-form-item):nth-child(5) {
    width: 60%;
    /* margin-top: 59px;
    margin-bottom: 24px; */
    & :global(.ant-select) {
      width: 50%;
    }
  }

  & :global(.ant-form-item):nth-child(6) {
    width: 60%;
    & :global(.ant-select) {
      width: 50%;
    }
  }

  & :global(.ant-form-item):nth-child(7) {
    width: 60%;

    & :global(.ant-form-item-control) {
      width: 50%;
    }
  }

  & :global(.ant-form-item):nth-child(8) {
    width: 60%;
    & :global(.ant-form-item-control) {
      width: 50%;
    }
  }

  & :global(.ant-form-item):nth-child(9) {
    width: 60%;
    & :global(.ant-form-item-control) {
      width: 50%;
    }
  }

  & :global(.ant-form-item) {
    & :global(.ant-input-number) {
      width: 50%;
      color: black !important;
    }

    & :global(.ant-picker) {
      width: 43.1%;
    }
  }

  & :global(.hiddenContainer) {
    display: none !important;
    //visibility: hidden !important
  }

  & :global(.clientContainer) {
    position: absolute;
    z-index: 10;
    right: 5.5%;
    width: 55%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & :global(.ant-form-item):nth-child(1) {
      width: 45%;

      & :global(.ant-input) {
        color: black !important;
      }
    }

    & :global(.ant-form-item):nth-child(2) {
      width: 45%;
      & :global(.ant-input) {
        color: black !important;
      }
    }

    & :global(.ant-form-item):nth-child(3) {
      width: 45%;
      & :global(.ant-select-selection-item) {
        color: black !important;
      }
    }

    & :global(.ant-form-item):nth-child(4) {
      width: 45%;
      & :global(.ant-input) {
        color: black !important;
      }
    }

    & :global(.ant-form-item):nth-child(5) {
      width: 100%;

      & :global(#clientNote) {
        min-height: 119px !important;
      }
    }

    & :global(.ant-form-item):nth-child(6) {
      width: 45%;

      & :global(.ant-select-in-form-item) {
        width: 100% !important;
      }

      & :global(.ant-select-selection-item-content) {
        color: black !important;
      }
    }

    & :global(.ant-form-item):nth-child(7) {
      width: 45%;

      & :global(.ant-form-item-control) {
        width: 100% !important;
      }
      
      & :global(.ant-select-selection-item-content) {
        color: black !important;
      }
    }
  }

  & :global(.ant-radio-group) {
    width: 50%;

    & :global(.ant-radio-button-wrapper) {
      width: 50%;
      text-align: center;
    }
  }
}

.searchFormContainer {
  margin-top: 50px;
  display: flex;
  width: 100%;

  & :global(.ant-form) {
    align-items: flex-end;
  }

  & :global(.ant-picker) {
    width: 100%;
  }

  & :global(.ant-form-item-label) label {
    font-size: 17px;
    font-weight: 600;
  }
}

.searchForm {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  & :global(.ant-form-item):nth-child(1) {
    margin-right: 2.153vw !important;
  }

  & :global(.ant-form-item):nth-child(2) {
    margin-right: 2.153vw !important;
  }

  & :global(.ant-form-item):nth-child(3) {
    width: 14%;
    margin-right: 2.153vw !important;
  }

  & :global(.ant-form-item):nth-child(4) {
    width: 13%;
    margin-right: 2.153vw !important;
  }
}

.newEntry {
  margin-top: 20px;
  background-color: #33a921;
}

.newEntry:hover {
  background-color: #2cbd16 !important;
}

.deleteEntry {
  margin-top: 20px;
  background-color: #990000;
}

.deleteEntry:hover {
  background-color: #af0000 !important;
}

.table {
  & :global(td) {
    border-top: 0 !important;
    border-bottom: 1px solid rgba(5, 5, 5, 0.06) !important;
  }

  & :global(.ant-select) {
    width: 110px !important;
  }

  & :global(td):nth-child(7) {
    width: 5%;
  }

  & :global(td):nth-child(8) {
    width: 5%;
  }

  span[title="Cancellato"] {
    color: red;
  }
  span[title="Arrivato"] {
    color: #33a921;
  }
  span[title="Prenotato"] {
    color: rgb(24, 144, 255);
  }
  span[title="No Show"] {
    color: #ff8000;
  }
}

.disabledRow {
  background-color: #efefef;
  border-radius: none !important;

  & :global(.ant-table-cell-row-hover) {
    background: none !important;
    background-color: transparent !important;
    border-bottom-color: rgba(5, 5, 5, 0.06) !important;
    border-bottom-color: 1px solid rgba(5, 5, 5, 0.06) !important;
  }

  & :global(.ant-table-cell) {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  & :global(.ant-table-cell-fix-right.ant-table-cell-row-hover) {
    background: #ffff !important;
  }
  & :global(.ant-table-cell-row-hover):nth-child(0) {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  & :global(.ant-table-cell-row-hover):last-child {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.defaultRow {
  & :global(.ant-table-cell-fix-right.ant-table-cell-row-hover) {
    background: #ffff !important;
  }

  & :global(.ant-table-cell-row-hover):first-child {
    border-start-start-radius: 0 !important;
    border-end-start-radius: 0 !important;
  }

  & :global(.ant-table-cell-row-hover):last-child {
    border-start-end-radius: 0 !important;
    border-end-end-radius: 0 !important;
  }
}

.textContainer {
  justify-content: center;
}

.text {
  margin: 20px 0 30px 0;
  font-size: 16px;
}

.modalStyle {
  height: 85%;
  & :global(.ant-btn-primary) {
    background-color: #33a921;
  }
  & :global(.ant-btn-primary):hover {
    background-color: #2cbd16 !important;
  }

  & :global(.ant-divider) {
    position: absolute !important;
    height: 82% !important;
    left: 35% !important;
    top: 8% !important;
  }
}

.modalEditStyle {
  height: 85%;

  & :global(.ant-divider) {
    position: absolute !important;
    height: 82% !important;
    left: 35% !important;
    top: 8% !important;
  }
}

.buttonContainer {
  display: flex;
  justify-content: space-between;

  .buttonCol {
    width: 15%;
    display: flex;
    justify-content: space-between;
  }
  .buttonCol:last-child {
    width: 25%;
    display: flex;
    justify-content: space-between;
  }
}

.clientInfo {
  display: flex;
  flex-direction: column;

  & .client {
    font-weight: 500;
  }

  & :global(.ant-divider) {
    position: unset !important;
  }
}
