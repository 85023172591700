.form {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  width: 100%;

  & :global(.ant-form-item-control) {
    width: 80% !important;
  }

  & input {
    cursor: default !important;
    color: black !important;
  }
}

.checkForm {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  width: 22%;

  & :global(.ant-form-item-control) {
    width: 80% !important;
  }

  & input {
    cursor: default !important;
    color: black !important;
  }
}

.checkLabel{
  font-size: 20px;
  margin-top: 30px;
}

.imgContainer {
  margin-top: 40px;
}

.homePageContainer {
  background-image: url("https://rione24.it/wp-content/uploads/2022/04/logo-rione-193x300.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 18%;
  height: 100%;
}
