.outflowForm {
  display: flex;
  flex-wrap: wrap;

  & :global(.ant-form-item) {
    width: 100%;

    & :global(.ant-input-number) {
      width: 80%;
      color: black !important;
    }

    & :global(.ant-select) {
      width: 100%;
    }

    & :global(.ant-picker) {
      width: 80%;
    }
  }

  & :global(.ant-form-item):nth-child(1) {
    width: 50%;
  }
  & :global(.ant-form-item):nth-child(2) {
    width: 50%;
  }
  & :global(.ant-form-item):nth-child(3) {
    width: 50%;
  }
  & :global(.ant-form-item):nth-child(4) {
    width: 50%;
  }
  & :global(.ant-form-item):nth-child(5) {
    width: 50%;

    & textarea {
      width: 80%;
      min-height: 153px;
      height: 100%;
    }
  }
}

.searchFormContainer {
  margin-top: 50px;
  display: flex;
  width: 100%;

  & :global(.ant-form) {
    align-items: flex-end;
  }

  & :global(.ant-form-item-label) label {
    font-size: 17px;
    font-weight: 600;
  }
}

.searchForm {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  & :global(.ant-form-item):nth-child(1) {
    margin-right: 2.153vw !important;
  }

  & :global(.ant-form-item):nth-child(2) {
    margin-right: 2.153vw !important;
  }

  & :global(.ant-form-item):nth-child(3) {
    margin-right: 2.153vw !important;
  }
}

.newEntry {
  margin-top: 20px;
  background-color: #33a921;
  color: white;
}

.newEntry:hover {
  background-color: #2cbd16 !important;
}

.deleteEntry {
  color: white;
  margin-top: 20px;
  background-color: #990000;
}

.deleteEntry:hover {
  background-color: #af0000 !important;
}

.table {
  & :global(td) {
    border-top: 0 !important;
    border-bottom: 1px solid rgba(5, 5, 5, 0.06) !important;
  }

  & :global(td):nth-child(7) {
    width: 5%,
  }
  & :global(td):nth-child(6) {
    width: 5%,
  }
}

.disabledRow {
  background-color: #efefef;
  border-radius: none !important;

  & :global(.ant-table-cell-row-hover) {
    background: none !important;
    background-color: transparent !important;
    border-bottom-color: rgba(5, 5, 5, 0.06) !important;
    border-bottom-color: 1px solid rgba(5, 5, 5, 0.06) !important;
  }

  & :global(.ant-table-cell) {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  & :global(.ant-table-cell-fix-right.ant-table-cell-row-hover) {
    background: #ffff !important;
  }
  & :global(.ant-table-cell-row-hover):nth-child(0) {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  & :global(.ant-table-cell-row-hover):last-child {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.defaultRow {
  & :global(.ant-table-cell-fix-right.ant-table-cell-row-hover) {
    background: #ffff !important;
  }

  & :global(.ant-table-cell-row-hover):first-child {
    border-start-start-radius: 0 !important;
    border-end-start-radius: 0 !important;
  }

  & :global(.ant-table-cell-row-hover):last-child {
    border-start-end-radius: 0 !important;
    border-end-end-radius: 0 !important;
  }
}

.textContainer {
  justify-content: center;
}

.text {
  margin: 20px 0 30px 0;
  font-size: 16px;
}

.modalStyle {
  & :global(.ant-btn-primary) {
    background-color: #33a921;
  }
  & :global(.ant-btn-primary):hover {
    background-color: #2cbd16 !important;
  }
}

.typoInput {
  width: 100%;
}

.modalTypoStyle {
  & :global(.ant-form-item) {
    width: 50%;
    margin-top: 30px;
  }

  & :global(.ant-btn-primary) {
    background-color: #33a921;
  }
  & :global(.ant-btn-primary):hover {
    background-color: #2cbd16 !important;
  }
}

.modalDelTypoStyle {
  & :global(.ant-form-item) {
    width: 50%;
    margin-top: 30px;
  }

  & :global(.ant-btn-primary) {
    background-color: #990000;
  }
  & :global(.ant-btn-primary):hover {
    background-color: #af0000 !important;
  }
}

.dropDownFooter {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.dropDownButtons{
  display: flex,
}
