.usersForm {
  display: flex;
  flex-wrap: wrap;

  & :global(.ant-form-item) {
    width: 100%;

    & :global(.ant-form-item-control) {
      width: 40%;
    }
  }
}

.newEntry {
  margin-top: 20px;
  background-color: #33a921;
}

.newEntry:hover {
  background-color: #2cbd16 !important;
}

.table {
  & :global(td) {
    border-top: 0 !important;
    border-bottom: 1px solid rgba(5, 5, 5, 0.06) !important;
  }
  & :global(td):nth-child(1) {
    width:38%,
  }
  & :global(td):nth-child(2) {
    width:38%,
  }
  & :global(td):nth-child(3) {
    width:5%,
  }
  & :global(td):nth-child(4) {
    width:5%,
  }
}

.textContainer {
  justify-content: center;
}

.text {
  margin: 20px 0 30px 0;
  font-size: 16px;
}

.modalStyle {
  & :global(.ant-btn-primary) {
    background-color: #33a921;
  }
  & :global(.ant-btn-primary):hover {
    background-color: #2cbd16 !important;
  }
}

.modalTypoStyle {
  & :global(.ant-form-item) {
    width: 50%;
    margin-top: 30px;
  }

  & :global(.ant-btn-primary) {
    background-color: #33a921;
  }
  & :global(.ant-btn-primary):hover {
    background-color: #2cbd16 !important;
  }
}
